import React, { useRef, useEffect, useState } from 'react'
import CountUp, { useCountUp } from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/effect-coverflow';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import {
    Row,
    Col,
} from "reactstrap"
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Newsletter from './Newsletter';
import { get, post } from '../../helpers/helper_api';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { ReactTyped } from "react-typed";

export default function Home(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [cats, setCats] = useState([]);
    const [field, setField] = useState([]);
    const swiperRef = useRef();
    const swiperRef1 = useRef();
    const swiperRef2 = useRef();
    const formRef = useRef();
    const [expandedIndexes, setExpandedIndexes] = useState([]);
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [])
    const toggleExpand = (index) => {
        if (expandedIndexes.includes(index)) {
            setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
        } else {
            setExpandedIndexes([...expandedIndexes, index]);
        }
    };

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            get("home")
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setData(res?.data);
                        console.log("location", window?.location);
                        if (res?.data?.home?.form?.length)
                            if (res?.data?.home?.form[0]?.field?.length)
                                setField(res?.data?.home?.form[0]?.field);
                        if (res?.data?.home)
                            if (props?.setHeaderData)
                                props?.setHeaderData({ ...props?.headerData, home: res?.data?.home });

                        console.log('home', res?.data?.home?.logo)
                        if (window?.location?.hash == '#workspace') {
                            // Scroll to the element with the id "properties"
                            const propertiesElement = document.getElementById('workspace');
                            if (propertiesElement) {
                                propertiesElement.scrollIntoView({ behavior: 'smooth' });
                            }
                        }
                    } else
                        toast.error(res?.error);
                })
                .catch(err => {
                    setLoading(false);
                    console.error("error while getting city drop from header", err);
                    toast.error("Something Went Wrong!");
                });
            get("category/list")
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        setCats(res?.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("Something Went Wrong!");
                })
        }
    }, []);

    console.log('category', cats?.length)

    function simplifyNumber(number) {
        const suffixes = ["", "K", "M", "B", "T"]; // Add more suffixes as needed
        let suffixIndex = 0;
        while (number >= 1000 && suffixIndex < suffixes.length - 1) {
            number /= 1000;
            suffixIndex++;
        }
        return {
            number: parseFloat(number.toFixed(1)), // Round to 1 decimal place
            str: suffixes[suffixIndex],
        };
    }

    const handleLeadForm = (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                form: field,
                from: "Home",
                interestedIn: "Lead Form",
                formPosition: "Bottom"
            }
            post("lead", body)
                .then(res => {
                    setLoading(false);
                    if (res?.statusCode == 200) {
                        // toast.success(res?.message);
                        formRef.current.reset();

                        setTimeout(() => {
                            navigate('/thank-you', {
                                state: { isThankuPage: true }
                            });
                        }, 500)
                    } else
                        toast.error(res?.error);
                })
                .catch(err => {
                    toast.error("Something Went Wrong!");
                    console.log("error while aubmitting lead form", err);
                    setLoading(false);
                })
        }
    }

    const handleFieldInput = (i, val) => {
        let temp = Object.assign([], field);
        temp[i].value = val;
        setField(temp);
    }

    const parseTitle = (title) => {
        if (title) {
            let str = title?.toLowerCase();
            str = str.split(" ").join("-");
            return str;
        } else {
            return title
        }
    }

    // console.log("SEO", data?.home?.seo);

    return (
        <>
            <Loader visible={loading} />
            {data?.home?.seo ?
                <Helmet>
                    {ReactHtmlParser(data?.home?.seo)}
                </Helmet>
                : null}
            <div className="home-banner">
                <div className='banner-img'>
                    <img src={data?.home?.image ? data?.home?.image : require('../../assets/images/home-banner.png')} alt='home-banner' />
                </div>
                <div className='banner-content'>
                    <div className='content-b'>
                        <h1>{data?.home?.title}<br></br>
                            {
                                data?.home?.subTitle?.length ?
                                    <ReactTyped
                                        backSpeed={50}
                                        loop={true}
                                        strings={data?.home?.subTitle?.split('-')}
                                        typeSpeed={50}
                                        typedRef={function noRefCheck() { }}
                                    />
                                    : null}
                        </h1>
                        {/* <p>{data?.home?.subTitle}</p> */}
                        <a href='#connect' className='text-decoration-none'>
                            <button className='button_2'>{data?.home?.buttonTitle}</button>
                        </a>
                    </div>
                </div>
            </div>
            <section className='counter-section top bottom padding-left-right'>
                <div className='counter-box'>
                    <ul>
                        <li>
                            <h5><CountUp end={data?.cityCount ? simplifyNumber(data?.cityCount).number : 0} />{data?.cityCount ? simplifyNumber(data?.cityCount).str : ""}+</h5>
                            <p>Cities</p>
                        </li>
                        <li>
                            <h5><CountUp end={data?.locationCount ? simplifyNumber(data?.locationCount).number : 0} />{data?.locationCount ? simplifyNumber(data?.locationCount).str : ""}+</h5>
                            <p>Locations</p>
                        </li>
                        <li>
                            <h5><CountUp end={data?.home?.member ? simplifyNumber(data?.home?.member).number : 0} />{data?.home?.member ? simplifyNumber(data?.home?.member).str : ""}+</h5>
                            <p>Members</p>
                        </li>
                        <li>
                            <h5><CountUp end={data?.home?.sqFeet ? simplifyNumber(data?.home?.sqFeet).number : 0} />{data?.home?.sqFeet ? simplifyNumber(data?.home?.sqFeet).str : ""}+</h5>
                            <p>Sq. ft</p>
                        </li>
                    </ul>
                </div>
            </section>
            {/* categories */}
            {cats?.length ?
                <section className='onward-workspaces-offerings bottom top-0 padding-left-right'>
                    <div className='workspaces-slider'>
                        <div className='heading-title'>
                            <h2 className='heading text-center workspaces'>{data?.home?.catHead}</h2>
                            <p className='paragraph' style={{ whiteSpace: 'pre-line' }}>{data?.home?.catPara}</p>
                        </div>

                        {
                            cats?.length <= 3 ?
                                <div className='normal-design-box'>
                                    <div className='row justify-content-center'>

                                        {cats?.map((item, index) => (
                                            <div className='col-lg-4 col-md-6'>
                                                <Link to={`/category/${parseTitle(item?.title)}`} className='text-decoration-none'>
                                                    <div className='slider-item'>
                                                        <div className='item-image item-category-img'>
                                                            <img src={item?.image ? item?.image : require('../../assets/images/item.png')} alt={item?.title} />
                                                        </div>
                                                        <div className='card-box'>
                                                            <h6>{item?.title}</h6>
                                                            <p>{item?.tagline}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                                :
                                <div className='slider-section slider-section-home-page'>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        // autoplay={{
                                        //     delay: 2500,
                                        //     disableOnInteraction: false,
                                        // }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 3.2,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 3.5,
                                                spaceBetween: 10,
                                            },
                                        }}
                                        onBeforeInit={(swiper) => {
                                            swiperRef.current = swiper;
                                        }}
                                        // navigation={true}
                                        modules={[Navigation]}
                                        className="mySwiper workspace swiper-section"
                                    >
                                        {cats?.map((item, index) => (
                                            <SwiperSlide key={item?._id}>
                                                <Link to={`/category/${parseTitle(item?.title)}`} className='text-decoration-none'>
                                                    <div className='slider-item'>
                                                        <div className='item-image item-category-img'>
                                                            <img src={item?.image ? item?.image : require('../../assets/images/item.png')} alt={item?.title} />
                                                        </div>
                                                        <div className='card-box'>
                                                            <h6>{item?.title}</h6>
                                                            <p>{item?.tagline}</p>
                                                            <span className='price'>{item?.startFrom}</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    {cats?.length > 4 &&
                                        <div className='cuttom-slider-button-box'>
                                            <button onClick={() => swiperRef.current?.slidePrev()} className="swiper-button-prev">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M42.6978 42.72L5.25781 42.72L5.25781 5.28003L42.6978 5.28003L42.6978 42.72Z" fill="white" />
                                                    <path className='icon_svg' d="M13.9206 0H34.0326C42.7686 0 47.9766 5.208 47.9766 13.944V34.032C47.9766 42.792 42.7686 48 34.0326 48H13.9446C5.20856 48 0.00056076 42.792 0.00056076 34.056V13.944C-0.0234375 5.208 5.18456 0 13.9206 0ZM17.2806 25.272L25.7526 33.744C26.1126 34.104 26.5686 34.272 27.0246 34.272C27.4806 34.272 27.9366 34.104 28.2966 33.744C28.9926 33.048 28.9926 31.896 28.2966 31.2L21.0966 24L28.2966 16.8C28.9926 16.104 28.9926 14.952 28.2966 14.256C27.6006 13.56 26.4486 13.56 25.7526 14.256L17.2806 22.728C16.5606 23.424 16.5606 24.576 17.2806 25.272Z" fill="#5A5A5A" />
                                                </svg>
                                            </button>
                                            <button onClick={() => swiperRef.current?.slideNext()} className="swiper-button-next">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.30219 42.72L42.7422 42.72L42.7422 5.28003L5.30219 5.28003L5.30219 42.72Z" fill="white" />
                                                    <path className='icon_svg' d="M34.0794 0H13.9674C5.23144 0 0.0234375 5.208 0.0234375 13.944V34.032C0.0234375 42.792 5.23144 48 13.9674 48H34.0554C42.7914 48 47.9994 42.792 47.9994 34.056V13.944C48.0234 5.208 42.8154 0 34.0794 0ZM30.7194 25.272L22.2474 33.744C21.8874 34.104 21.4314 34.272 20.9754 34.272C20.5194 34.272 20.0634 34.104 19.7034 33.744C19.0074 33.048 19.0074 31.896 19.7034 31.2L26.9034 24L19.7034 16.8C19.0074 16.104 19.0074 14.952 19.7034 14.256C20.3994 13.56 21.5514 13.56 22.2474 14.256L30.7194 22.728C31.4394 23.424 31.4394 24.576 30.7194 25.272Z" fill="#5A5A5A" />
                                                </svg>


                                            </button>
                                        </div>
                                    }
                                </div>
                        }


                    </div>


                </section >
                : null}
            {/* <section className="requirement-section top-0 bottom padding-left-right">
                <div className='cotent-section'>
                    <Row>
                        <Col lg={6}>
                            <div className='left-section'>
                                <h2 className='heading requirement-heading'>{data?.home?.connHead}</h2>
                                <p className='paragraph'>{data?.home?.connPara}</p>

                                <a href='#connect' className='text-decoration-none'>
                                    <button className='button_2'>Let’s Connect</button>
                                </a>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='right-section mt_50px'>
                                <img src={require('../../assets/images/requirements.png')} alt='requirements' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section> */}
            {/* why onward */}
            {data?.home?.whyOnward?.length ?
                <section className='full-section top bottom'>
                    <div className='revolutionise-section'>
                        <div className='heading-title'>
                            <h3 className='heading text-center revolutionise'>{data?.home?.whyHead}</h3>
                            <p className='paragraph text-center' style={{ whiteSpace: 'pre-line' }}>{data?.home?.whyPara}</p>
                            {/* <a href='#connect' className='text-decoration-none'>
                                <button className='button_2'>Let’s Connect</button>
                            </a> */}
                        </div>
                        <div className='revo-card-section padding-left-right'>
                            <Row style={{ justifyContent: 'center' }}>
                                {data?.home?.whyOnward?.map((item, index) => (
                                    <Col lg={3} md={4} sm={6}>
                                        <div className='revo-card-box active'>
                                            <div className='icon-box'>
                                                <img src={item?.image} alt={item?.title} />
                                            </div>
                                            <h4>{item?.title}</h4>
                                            <p>{item?.desc}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </section>
                : null}
            {/* PROPERTIES */}
            {data?.property?.length ?
                <section className='onward-workspaces-offerings top bottom padding-left-right' id="workspace">
                    <div className='workspaces-slider'>
                        <div className='heading-title'>
                            <h2 className='heading text-center workspaces'>{data?.home?.propHead}</h2>
                            <p className='paragraph' style={{ whiteSpace: 'pre-line' }}>{data?.home?.propPara}</p>
                        </div>
                        {data?.property?.length <= 3 ?
                            <div className='normal-design-box'>
                                <div className='row justify-content-center'>
                                    {data?.property?.map((item, index) => (
                                        <div className='col-lg-4 col-md-6'>
                                            <Link to={`/${parseTitle(item?.city[0]?.title)}/${parseTitle(item?.location[0]?.path)}/${parseTitle(item?.path)}`} className='text-decoration-none'>
                                                <div className='slider-item'>
                                                    <div className='item-image item-category-img'>
                                                        <img src={item?.images?.length ? item?.images[0] : require('../../assets/images/item.png')} alt={item?.title} />
                                                    </div>
                                                    <div className='card-box'>
                                                        <h6 style={{ paddingLeft: 10, paddingRight: 10 }}>{item?.title}</h6>
                                                        <p>Capacity {item?.seat}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            :
                            <div className='slider-section'>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2.8,
                                            spaceBetween: 10,
                                        },
                                        1024: {
                                            slidesPerView: 3.5,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiperRef1.current = swiper;
                                    }}
                                    // navigation={true}
                                    modules={[Navigation, Autoplay]}
                                    className="mySwiper workspace swiper-section"
                                >
                                    {data?.property?.map((item, index) => (
                                        <SwiperSlide key={item?._id}>
                                            <Link to={`/${parseTitle(item?.city[0]?.path)}/${parseTitle(item?.location[0]?.path)}/${parseTitle(item?.path)}`} className='text-decoration-none'>
                                                <div className='slider-item'>
                                                    <div className='item-image item-category-img'>
                                                        <img src={item?.images?.length ? item?.images[0] : require('../../assets/images/item.png')} alt={item?.title} />
                                                    </div>
                                                    <div className='card-box'>
                                                        <h6 style={{ paddingLeft: 10, paddingRight: 10 }}>{item?.title}</h6>
                                                        <p>Capacity {item?.seat}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                {data?.property?.length > 4 &&
                                    <div className='cuttom-slider-button-box'>
                                        <button onClick={() => swiperRef1.current?.slidePrev()} className="swiper-button-prev">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M42.6978 42.72L5.25781 42.72L5.25781 5.28003L42.6978 5.28003L42.6978 42.72Z" fill="white" />
                                                <path className='icon_svg' d="M13.9206 0H34.0326C42.7686 0 47.9766 5.208 47.9766 13.944V34.032C47.9766 42.792 42.7686 48 34.0326 48H13.9446C5.20856 48 0.00056076 42.792 0.00056076 34.056V13.944C-0.0234375 5.208 5.18456 0 13.9206 0ZM17.2806 25.272L25.7526 33.744C26.1126 34.104 26.5686 34.272 27.0246 34.272C27.4806 34.272 27.9366 34.104 28.2966 33.744C28.9926 33.048 28.9926 31.896 28.2966 31.2L21.0966 24L28.2966 16.8C28.9926 16.104 28.9926 14.952 28.2966 14.256C27.6006 13.56 26.4486 13.56 25.7526 14.256L17.2806 22.728C16.5606 23.424 16.5606 24.576 17.2806 25.272Z" fill="#5A5A5A" />
                                            </svg>


                                        </button>
                                        <button onClick={() => swiperRef1.current?.slideNext()} className="swiper-button-next">
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.30219 42.72L42.7422 42.72L42.7422 5.28003L5.30219 5.28003L5.30219 42.72Z" fill="white" />
                                                <path className='icon_svg' d="M34.0794 0H13.9674C5.23144 0 0.0234375 5.208 0.0234375 13.944V34.032C0.0234375 42.792 5.23144 48 13.9674 48H34.0554C42.7914 48 47.9994 42.792 47.9994 34.056V13.944C48.0234 5.208 42.8154 0 34.0794 0ZM30.7194 25.272L22.2474 33.744C21.8874 34.104 21.4314 34.272 20.9754 34.272C20.5194 34.272 20.0634 34.104 19.7034 33.744C19.0074 33.048 19.0074 31.896 19.7034 31.2L26.9034 24L19.7034 16.8C19.0074 16.104 19.0074 14.952 19.7034 14.256C20.3994 13.56 21.5514 13.56 22.2474 14.256L30.7194 22.728C31.4394 23.424 31.4394 24.576 30.7194 25.272Z" fill="#5A5A5A" />
                                            </svg>


                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </section>
                : null}
            {/* City */}
            {/* {data?.city?.length &&
                <section className='onward-workspaces-offerings top bottom padding-left-right'>
                    <div className='workspaces-slider'>
                        <div className='heading-title'>
                            <h3 className='heading text-center workspaces'>Explore By Area</h3>
                            <p className='paragraph'>Alluring properties in dazzling Ahmedabad are waiting for highly discerning tastes under various categories and fascinating<br /> locations listed here.​</p>
                        </div>
                        <div className='explore-section'>
                            <Row>
                                {data?.city?.length > 0 &&
                                    <Col lg={6} md={12}>
                                        <Link to={`/${parseTitle(data?.city[0]?.title)}`}>
                                            <div className='thumb-image-box'>
                                                <img src={data?.city[0]?.image ? data?.city[0]?.image : require('../../assets/images/item-i.png')} alt={data?.city[0]?.title} />
                                                <div className='explore-conetn'>
                                                    <h4>{data?.city[0]?.title}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                }
                                {data?.city?.length > 1 &&
                                    <Col lg={6} md={12}>
                                        <Link to={`/${parseTitle(data?.city[1]?.title)}`}>
                                            <div className='thumb-image-box'>
                                                <img src={data?.city[1]?.image ? data?.city[1]?.image : require('../../assets/images/item-i.png')} alt={data?.city[1]?.title} />
                                                <div className='explore-conetn'>
                                                    <h4>{data?.city[1]?.title}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                }
                                {data?.city?.length > 2 &&
                                    <Col lg={4} md={12}>
                                        <Link to={`/${parseTitle(data?.city[2]?.title)}`}>
                                            <div className='thumb-image-box thumb_image_box'>
                                                <img src={data?.city[2]?.image ? data?.city[2]?.image : require('../../assets/images/item-i.png')} alt={data?.city[2]?.title} />
                                                <div className='explore-conetn'>
                                                    <h4>{data?.city[2]?.title}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                }
                                {data?.city?.length > 3 &&
                                    <Col lg={4} md={12}>
                                        <Link to={`/${parseTitle(data?.city[3]?.title)}`}>
                                            <div className='thumb-image-box thumb_image_box'>
                                                <img src={data?.city[3]?.image ? data?.city[3]?.image : require('../../assets/images/item-i.png')} alt={data?.city[3]?.title} />
                                                <div className='explore-conetn'>
                                                    <h4>{data?.city[3]?.title}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                }
                                {data?.city?.length > 4 &&
                                    <Col lg={4} md={12}>
                                        <Link to={`/${parseTitle(data?.city[4]?.title)}`}>
                                            <div className='thumb-image-box thumb_image_box'>
                                                <img src={data?.city[4]?.image ? data?.city[4]?.image : require('../../assets/images/item-i.png')} alt={data?.city[4]?.title} />
                                                <div className='explore-conetn'>
                                                    <h4>{data?.city[4]?.title}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </section>
            } */}
            {/* Lead form */}
            {data?.home?.form?.length ?
                <section className="requirement-section top bottom padding-left-right" id="connect">
                    <div className='cotent-section requirement-form-section'>
                        <Row>

                            <Col lg={6}>
                                <div className='right-section'>
                                    <img src={data?.home?.form[0]?.image ? data?.home?.form[0]?.image : require('../../assets/images/form-img.png')} alt='lead-form-img' style={{ borderRadius: 20 }} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='left-section get_form'>
                                    <h3 className='heading requirement-heading'>{data?.home?.form[0]?.heading}</h3>
                                    <p className='paragraph'>{data?.home?.form[0]?.desc}</p>
                                    <AvForm ref={formRef} className="requirement-form" onValidSubmit={handleLeadForm}>
                                        {field?.map((item, index) => (
                                            <AvField
                                                type={item?.type}
                                                name={item?.type}
                                                placeholder={`Enter ${item?.title}`}
                                                onChange={(e) => handleFieldInput(index, e.target.value)}
                                                required
                                            />
                                        ))}
                                        <button className='button_2'>SUBMIT</button>
                                    </AvForm>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                : null}
            {/* Testimonial */}
            {data?.testi?.length ?
                <section className='onward-workspaces-offerings top-0 bottom padding-left-right'>
                    <div className='workspaces-slider'>
                        <div className='heading-title'>
                            <h4 className='heading text-center workspaces'>{data?.home?.testiHead}</h4>
                            <p className='paragraph' style={{ whiteSpace: 'pre-line' }}>{data?.home?.testiPara}</p>
                        </div>
                        {
                            data?.testi?.length <= 3 ?
                                <div className='normal-design-box'>
                                    <div className='row justify-content-center'>
                                        {data?.testi?.map((item, index) => {

                                            return (

                                                <div className='col-lg-4 col-md-6'>

                                                    <div className='slider-item'>

                                                        <div className='card_box'>
                                                            <p style={{ fontSize: 14 }}>{expandedIndexes.includes(index) ? item.desc : `${item.desc.slice(0, 180)}...`} <button className='read-more' onClick={() => toggleExpand(index)}>{expandedIndexes.includes(index) ? ' Read Less' : ' Read More'}</button><br /></p>
                                                            <div className='card-footer-box'>
                                                                <div className='left-card-1'>
                                                                    <img src={item?.image ? item?.image : require('../../assets/images/team.png')} alt={item?.name} />
                                                                </div>
                                                                <div className='right-card-2'>
                                                                    <h5>
                                                                        {item?.name}
                                                                        {/* Senior Procurement */}
                                                                    </h5>
                                                                    <span>{item?.desig}</span>
                                                                    {/* <span>Senior Procurement Leader | Aramex</span> */}
                                                                </div>
                                                            </div>


                                                            <div className='rating_box'>
                                                                {item?.rating > 0 &&
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                    </svg>
                                                                }
                                                                {item?.rating > 1 &&
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                    </svg>
                                                                }
                                                                {item?.rating > 2 &&
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                    </svg>
                                                                }
                                                                {item?.rating > 3 &&
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                    </svg>
                                                                }
                                                                {item?.rating > 4 &&
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                    </svg>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className='item-image'>
                                                            <img src={item?.image ? item?.image : require('../../assets/images/team.png')} alt={item?.name} />
                                                        </div> */}
                                                    </div>


                                                </div>

                                            )
                                        })}
                                    </div>
                                </div>
                                :
                                <div className='slider-section'>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 2,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 3.2,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 3.4,
                                                spaceBetween: 10,
                                            },
                                        }}
                                        onBeforeInit={(swiper) => {
                                            swiperRef2.current = swiper;
                                        }}

                                        // navigation={true}
                                        modules={[Navigation, Autoplay]}
                                        className="mySwiper workspace swiper-section"
                                    >
                                        {data?.testi?.map((item, index) => (
                                            <SwiperSlide>
                                                <div className='slider-item'>
                                                    <div className='item-image'>
                                                        <img src={item?.image ? item?.image : require('../../assets/images/team.png')} alt={item?.name} />
                                                    </div>
                                                    <div className='card_box'>
                                                        <p style={{ fontSize: 14 }}>{expandedIndexes.includes(index) ? item.desc : `${item.desc.slice(0, 120)}...`} <button className='read-more' onClick={() => toggleExpand(index)}>{expandedIndexes.includes(index) ? ' Read Less' : ' Read More'}</button><br /></p>

                                                        <span>{item?.name}</span>
                                                        <div className='rating_box'>
                                                            {item?.rating > 0 &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                </svg>
                                                            }
                                                            {item?.rating > 1 &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                </svg>
                                                            }
                                                            {item?.rating > 2 &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                </svg>
                                                            }
                                                            {item?.rating > 3 &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                </svg>
                                                            }
                                                            {item?.rating > 4 &&
                                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="Vector" d="M8.31359 0.205137L10.5966 5.05692L15.7 5.83512C15.7648 5.84519 15.8256 5.87396 15.8756 5.9182C15.9255 5.96243 15.9627 6.02037 15.9829 6.0855C16.0031 6.15062 16.0054 6.22034 15.9898 6.28681C15.9741 6.35327 15.941 6.41384 15.8941 6.46169L12.202 10.2374L13.0737 15.57C13.0849 15.6374 13.0779 15.7067 13.0533 15.7701C13.0288 15.8336 12.9877 15.8885 12.9348 15.9288C12.8819 15.9691 12.8193 15.9931 12.754 15.998C12.6887 16.003 12.6234 15.9887 12.5655 15.9569L8.00052 13.4397L3.43557 15.9576C3.37769 15.9896 3.31238 16.0039 3.24708 15.9991C3.18178 15.9942 3.11909 15.9703 3.06616 15.93C3.01322 15.8897 2.97214 15.8347 2.94761 15.7713C2.92307 15.7078 2.91605 15.6385 2.92734 15.571L3.79908 10.2374L0.105859 6.46169C0.0590308 6.41384 0.0259117 6.35327 0.0102315 6.28681C-0.00544867 6.22034 -0.00306709 6.15062 0.0171081 6.0855C0.0372832 6.02037 0.0744506 5.96243 0.124425 5.9182C0.174398 5.87396 0.235194 5.84519 0.299965 5.83512L5.40341 5.05692L7.68745 0.205137C7.71593 0.143655 7.76041 0.0918035 7.81578 0.055523C7.87115 0.0192424 7.93517 0 8.00052 0C8.06587 0 8.1299 0.0192424 8.18527 0.055523C8.24063 0.0918035 8.28511 0.143655 8.31359 0.205137Z" fill="#111111" />
                                                                </svg>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    {data?.testi?.length > 3 &&
                                        <div className='cuttom-slider-button-box'>
                                            <button onClick={() => swiperRef2.current?.slidePrev()} className="swiper-button-prev">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M42.6978 42.72L5.25781 42.72L5.25781 5.28003L42.6978 5.28003L42.6978 42.72Z" fill="white" />
                                                    <path className='icon_svg' d="M13.9206 0H34.0326C42.7686 0 47.9766 5.208 47.9766 13.944V34.032C47.9766 42.792 42.7686 48 34.0326 48H13.9446C5.20856 48 0.00056076 42.792 0.00056076 34.056V13.944C-0.0234375 5.208 5.18456 0 13.9206 0ZM17.2806 25.272L25.7526 33.744C26.1126 34.104 26.5686 34.272 27.0246 34.272C27.4806 34.272 27.9366 34.104 28.2966 33.744C28.9926 33.048 28.9926 31.896 28.2966 31.2L21.0966 24L28.2966 16.8C28.9926 16.104 28.9926 14.952 28.2966 14.256C27.6006 13.56 26.4486 13.56 25.7526 14.256L17.2806 22.728C16.5606 23.424 16.5606 24.576 17.2806 25.272Z" fill="#5A5A5A" />
                                                </svg>
                                            </button>
                                            <button onClick={() => swiperRef2.current?.slideNext()} className="swiper-button-next">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.30219 42.72L42.7422 42.72L42.7422 5.28003L5.30219 5.28003L5.30219 42.72Z" fill="white" />
                                                    <path className='icon_svg' d="M34.0794 0H13.9674C5.23144 0 0.0234375 5.208 0.0234375 13.944V34.032C0.0234375 42.792 5.23144 48 13.9674 48H34.0554C42.7914 48 47.9994 42.792 47.9994 34.056V13.944C48.0234 5.208 42.8154 0 34.0794 0ZM30.7194 25.272L22.2474 33.744C21.8874 34.104 21.4314 34.272 20.9754 34.272C20.5194 34.272 20.0634 34.104 19.7034 33.744C19.0074 33.048 19.0074 31.896 19.7034 31.2L26.9034 24L19.7034 16.8C19.0074 16.104 19.0074 14.952 19.7034 14.256C20.3994 13.56 21.5514 13.56 22.2474 14.256L30.7194 22.728C31.4394 23.424 31.4394 24.576 30.7194 25.272Z" fill="#5A5A5A" />
                                                </svg>
                                            </button>
                                        </div>
                                    }
                                </div>
                        }
                    </div>
                </section>
                : null}
            {data?.home?.clientale?.length ?
                <section className='clients-section top-0 bottom mt-0 padding-left-right'>
                    <div className='clients_box'>
                        <div className='heading-title'>
                            <h4 className='heading text-center gallery_slider_heading workspaces'>{data?.home?.cliHead}</h4>
                            <p className='paragraph' style={{ whiteSpace: 'pre-line' }}>{data?.home?.cliPara}</p>
                        </div>
                        {
                            data?.home?.clientale?.length <= 6 ?
                                <div className='normal-design-box client_logo-slider'>
                                    <div className='row justify-content-center'>
                                        {data?.home?.clientale?.map((item) => (
                                            <div className='col-lg-2 col-md-4 col-sm-6' key={item?._id}>
                                                <div className='clients-item'>
                                                    <img src={item?.image ? item?.image : require('../../assets/images/client.png')} alt={'client'} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                :
                                <div className='slider-section'>
                                    <Swiper
                                        slidesPerView={2.4}
                                        spaceBetween={10}
                                        // pagination={{
                                        //     clickable: true,
                                        // }}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            768: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                            },
                                            1024: {
                                                slidesPerView: 6,
                                                spaceBetween: 10,
                                            },
                                        }}

                                        // navigation={true}
                                        modules={[Autoplay]}
                                        className="mySwiper client_logo-slider swiper-section p-2"
                                    >
                                        {data?.home?.clientale?.map((item) => (
                                            <SwiperSlide key={item?._id}>
                                                <div className='clients-item'>
                                                    <img src={item?.image ? item?.image : require('../../assets/images/client.png')} alt={'client'} />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>

                                </div>

                        }

                    </div>
                </section>
                : null}
            {/* Gallery */}
            <section className='onward-workspaces-offerings top-0 gallery_section bottom padding-left-right'>
                <div className='workspaces-slider'>
                    <div className='heading-title'>
                        <h4 className='heading text-center  gallery_slider_heading workspaces'>{data?.home?.galHead}</h4>
                        <p className='paragraph' style={{ whiteSpace: 'pre-line' }}>{data?.home?.galPara}</p>
                    </div>

                    {
                        data?.gallery?.length <= 3 ?
                            <div className='normal-design-box gallery-section-normal'>
                                <div className='row justify-content-center gallery_slider'>
                                    {data?.gallery?.map((item) => (
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='gallery-item'>
                                                <img src={item ? item : require('../../assets/images/gallery.png')} alt='gallery' />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className='slider-section'>
                                <Swiper
                                    // effect={'coverflow'}
                                    grabCursor={true}
                                    loop={true}
                                    // centeredSlides={true}
                                    slidesPerView={'auto'}
                                    // coverflowEffect={{
                                    //     rotate: 10,
                                    //     stretch: 0,
                                    //     depth: -80,
                                    //     modifier: 1,
                                    //     slideShadows: false,
                                    // }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2.8,
                                            spaceBetween: 10,
                                        },
                                        1024: {
                                            slidesPerView: 3.5,
                                            spaceBetween: 10,
                                        },
                                    }}

                                    // navigation={true}
                                    modules={[Navigation, EffectCoverflow, Autoplay]}
                                    className="mySwiper gallery_slider swiper-section"
                                >
                                    {data?.gallery?.map((item) => (
                                        <SwiperSlide>
                                            <div className='gallery-item'>
                                                <img src={item ? item : require('../../assets/images/gallery.png')} alt='gallery' />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                {/* <div className='cuttom-slider-button-box'>
                            <button onClick={() => swiperRef1.current?.slidePrev()} className="swiper-button-prev">
                                <img src={Left} alt='left' />
                            </button>
                            <button onClick={() => swiperRef.current?.slideNext()} className="swiper-button-next">
                                <img src={Right} alt='right' />
                            </button>
                        </div> */}
                            </div>
                    }

                </div>
            </section>
            <section className='onward-workspaces-offerings top-0 gallery_section bottom padding-left-right'>
                <div dangerouslySetInnerHTML={{ __html: data?.home?.bContent }}>
                </div>
            </section>
            <Newsletter loading={loading} setLoading={setLoading} />
        </>
    )
}
